import React, { useState, useContext, useMemo } from 'react'
import { Button, message, Steps, theme, Form, Spin } from 'antd'
import FirstStep from '../steps/FirstStep'
import SecondStep from '../steps/SecondStep'
import ThirdStep from '../steps/ThirdStep'
import LastStep from '../steps/LastStep'
import styled from 'styled-components'
import { FormContext } from '../../App'
import axios from 'axios'

const Wizard = () => {
  const { token } = theme.useToken()
  const [current, setCurrent] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)

  const [quoteData, setQuoteData] = useState(undefined)

  const [firstForm] = Form.useForm()
  const [secondForm] = Form.useForm()
  const [thirdForm] = Form.useForm()
  const [lastForm] = Form.useForm()

  const ctx = useContext(FormContext)
  const { firstStep, secondStep, thirdStep, lastStep } = ctx

  const steps = useMemo(() => {
    console.log(ctx)
    return [
      {
        content: <FirstStep form={firstForm} />,
      },
      {
        content: <SecondStep form={secondForm} />,
      },
      {
        content: <ThirdStep form={thirdForm} />,
      },
      {
        content: <LastStep form={lastForm} />,
      },
    ]
  }, [firstForm, secondForm, thirdForm, lastForm, ctx])

  let form = firstForm

  if (current === 1) {
    form = secondForm
  } else if (current === 2) {
    form = thirdForm
  } else if (current === 3) {
    form = lastForm
  }

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const postFormData = () => {
    // send form data to server

    let reqData = {
      ...firstStep,
      ...secondStep,
      ...thirdStep,
      ...lastStep,
    }

    lastStep.pickupAddress = JSON.parse(window.localStorage.getItem("pickupAddress"));
    lastStep.dropOffAddress = JSON.parse(window.localStorage.getItem("dropOffAddress"));

    const destination = [lastStep?.dropOffAddress?.location || '']
    const origin = [lastStep?.pickupAddress?.location || '']

    console.log(lastStep?.dropOffAddress);
    console.log(lastStep?.pickupAddress);

    const travelMode = 'DRIVING'

    setLoading(true)

    if(!lastStep?.dropOffAddress?.location){
      setLoading(false)
      return message.error("Failed to get drop-off address, please try again")
    } else if(!lastStep?.pickupAddress?.location){
      setLoading(false)
      return message.error("Failed to get pickup address, please try again")
    }
    console.log("calling sendmail")

    //https://smart-mover.herokuapp.com/ 
    //https://git.heroku.com/smart-mover.git

    axios
      // .post('https://z9birb-8000.csb.app/send-mail',
      .post('https://smart-mover.herokuapp.com/send-mail',
       {
        ...reqData,
        origin,
        destination,
        travelMode,
      })
      .then((res) => res.data)
      .then((data) => {
        console.log(data)
        message.success('Successfully submitted form!')
        console.log(data.data)
        setQuoteData(data.data)
        localStorage.clear();
        setLoading(false)
      })
      .catch((err) => {
        message.error("Failed to get quotation, please try again later")
        setLoading(false)
      })
  }

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }))

  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    overflowY: 'scroll',
  }

  const checkIsValid = async () => {
    try {
      await form.validateFields()
      console.log('isValid', true)
      setIsValid(true)
      postFormData()
    } catch (e) {
      if (e?.errorFields?.length === 0) {
      }
      console.log('isValid', false)
      setIsValid(false)
      message.error('Enter all missing fields')
      console.log('error', e)
    }
  }

  return (
    <>
      <Steps current={current} items={items} />
      <>
        <Spin spinning={loading} style={contentStyle}>
          {steps[current].content}
        </Spin>
      </>
      {current === steps.length - 1 && isValid && (
        <CalcWrapper>
          {quoteData?.distance && (
            <h3>
              Average Distance: &nbsp; <br/><br/><span>{quoteData.distance} KM</span>
            </h3>
          )}
          {quoteData?.quote && (
            <h3>
              Estimate Quote: &nbsp; <br/><br/><span>R {quoteData?.quote}</span>
            </h3>
          )}
          {quoteData?.duration && (
            <h3>
              Average Duration: &nbsp; <br/><br/><span>{quoteData.duration}</span>
            </h3>
          )}
        </CalcWrapper>
      )}
      <div
        style={{
          marginTop: 24,
          // marginBottom: 10,
          overflowY: 'scroll',
        }}
      >
        {current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={async () => {
              try {
                await form.validateFields()
                console.log('isValid', true)
                next()
              } catch (e) {
                if (e.errorFields.length === 0) {
                  next()
                }
                console.log('isValid', false)
                console.log('error', e)
              }
            }}
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            loading={loading}
            type="primary"
            onClick={async () => {
              try {
                // await form.validateFields()
                // console.log('isValid', true)
                checkIsValid()
              } catch (e) {
                if (e.errorFields.length === 0) {
                  postFormData()
                }
                console.log('isValid', false)
                console.log('error', e)
              }
            }}
          >
            Calculate
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </div>
    </>
  )
}

const CalcWrapper = styled.div`
  text-align: left;
  font-weight: 800;
  color: '#1677ff';
  margin-left: 0px;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & h3 {
    color: #1677ff;
    font-weight: 800;
    font-size: 25px;
  }
  & span {
    color: lightgrey;
    font-weight: 800;
    font-size: 25px;
  }
`

export default Wizard
