import * as React from 'react'
import { Form, Input, Row, Col } from 'antd'
import NumericInput from '../UI/NumericInput'
import { FormContext } from '../../App'
import './steps.css'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 24 },
}

export default function FirstStep(props) {
  const ctx = React.useContext(FormContext)
  const onFinish = (values) => {
    console.log(values)
  }

  return (
    <Row justify="center">
      <Col xs={24} lg={22} xl={20} xxl={18}>
        <Form
          {...layout}
          layout="vertical"
          form={props.form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ padding: 30, marginTop: 70 }}
          initialValues={ctx.firstStep}
          onValuesChange={async (changedValues, allValues) => {
            // const values = {...allValues, location: pickupAddress}
            ctx.setFirstStep(allValues)
            console.log('allValues', allValues)
          }}
        >
          <Form.Item
            name="fullName"
            label="Full Name: "
            rules={[{ required: true, min: 2 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address: "
            rules={[{ required: true, min: 2, type: 'email' }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            name="location"
            label="Location: "
            rules={[{ required: true, min: 2 }]}
          >
            <Autocomplete
              apiKey={YOUR_GOOGLE_MAPS_API_KEY}
              className="form_item"
              inputAutocompleteValue={pAddress}
              // googleMapsScriptBaseUrl="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPauGIUstu-hi4LT08OYGOzDpoYMKjANg&libraries=places"
              onPlaceSelected={async (place) => {
                // console.log(place)
                console.log(place.formatted_address)
                // console.log(place.geometry.location.lng())
                setPAddress(place.formatted_address)
                //                 setPickupAddress({fullAdress: place.formatted_address, location: {lat: place.geometry.location.lat(), lng:place.geometry.location.lng()}
                // })
              }}
              options={{
                types: ['geocode'],
                componentRestrictions: { country: 'za' },
              }}
            />
          </Form.Item> */}
          <Form.Item name="phone" label="Phone: " rules={[{ required: true }]}>
            <NumericInput />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}
